<template>
  <v-app>
    <v-progress-linear indeterminate v-show="isHttpActive" color="purple lighten-1" width="2"
      class="app-progress-linear"></v-progress-linear>
    <component v-bind:is="layout"></component>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <v-btn
        :color="!!snackbarColor ? undefined : 'pink'"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>

import MainLayout from './layouts/MainLayout'

export default {
  name: 'App',

  data: () => ({
    snackbar: false,
    snackbarText: '',
    snackbarColor: '',
    invalidToken: false
  }),

  computed: {
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    isHttpActive () {
      return this.$store.state.loading
    },
    layout () {
      return (this.$route.meta.layout || 'main') + '-layout'
    }
  },

  created: function () {
    this.$eventHub.$on('token-invalid', () => this.revokeToken())
    this.$eventHub.$on('loading-start', () => this.startLoading())
    this.$eventHub.$on('loading-finish', () => this.stopLoading())
    this.$eventHub.$on('snackbar-message', (message, color) => this.showSnackbar(message, color))
  },

  beforeDestroy: function () {
    this.$eventHub.$off('token-invalid')
    this.$eventHub.$off('loading-start')
    this.$eventHub.$off('loading-finish')
    this.$eventHub.$off('snackbar-message')
  },

  methods: {
    logout: function (redirectTo) {
      sessionStorage.removeItem('cupones-token')
      this.$store.state.loggedIn = false
      this.$router.push({name: redirectTo || 'comprar'}, () => {})
    },
    startLoading: function () {
      this.$store.state.loading = true
    },
    stopLoading: function () {
      this.$store.state.loading = false

      if (this.invalidToken) {
        this.invalidToken = false
        this.showSnackbar('La sesión ha caducado')
        this.logout('login')
      }
    },
    revokeToken: function () {
      this.invalidToken = true
      this.stopLoading()
    },
    showSnackbar: function (message, color) {
      this.snackbarText = message
      this.snackbarColor = color
      this.snackbar = true
    }
  },

  components: {
    'main-layout': MainLayout
  },
};
</script>

<style scoped>
  .app-progress-linear {
    z-index: 10;
    position: fixed;
    margin-top: 0;
    top: 0;
    left: 0;
  }

  .text-monospace {
    font-family: 'Roboto Mono', monospace;
  }
</style>
