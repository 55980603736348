import axios from 'axios'
import eventHub from './eventHub'

const http = axios.create ({
  baseURL: (process.env.VUE_APP_API && (location.hostname !== '192.168.10.200'))
    ? process.env.VUE_APP_API
    : location.origin + '/proxy.php?path='
});

let activeHttpRequests = 0

http.interceptors.request.use(function (config) {
    config.params = { ...config.params, t: Date.now() }

    if (config.skipHijack) {
      return config
    }

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-start')
    }

    activeHttpRequests++;

    // JWT
    const token = sessionStorage.getItem('cupones-token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  }, function (error) {
    console.info(error, activeHttpRequests)
    return Promise.reject(error);
  });

http.interceptors.response.use(function (response) {
    if (response.config?.skipHijack) {
      return response
    }

    activeHttpRequests--;

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-finish')
    }

    return response;
  }, function (error) {
    if (error?.response?.config?.skipHijack) {
      return Promise.reject(error)
    }

    activeHttpRequests--;

    if (error.response && error.response.status === 401) {
      eventHub.$emit('token-invalid')
    }

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-finish')
    }

    return Promise.reject(error);
  });

export default http;
