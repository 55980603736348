<template>
	<v-app id="inspire">
		<v-app-bar
			app
			prominent
			dense
			color="#fde1bb"
			:src="require('../assets/formas.png')"
			hide-on-scroll
		>
			<template v-slot:img="{ props }">
				<v-img
					v-bind="props"
					contain
					position="center right"
					gradient="to top right, rgba(253, 225, 187, .33), rgba(253, 225, 187, .7)"
				></v-img>
			</template>

			<v-app-bar-title class="text-center">
				<v-img
					:src="require('../assets/logo_invert.png')"
					contain
					position="top left"
					height="35"
					alt="Complejo Americano"
				></v-img>
			</v-app-bar-title>

			<v-spacer></v-spacer>

			<v-btn v-if="!$store.state.loggedIn" icon :to="{ name: 'login' }" exact tabindex="0" title="Iniciar Sesión" class="d-none d-md-flex">
				<v-icon>mdi-account-circle-outline</v-icon>
			</v-btn>

			<v-menu
				bottom
				left
				v-else
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						icon
						color="primary"
						v-bind="attrs"
						v-on="on"
						class="d-none d-md-flex"
					>
						<v-icon>mdi-account-circle</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item :to="{ name: 'cuenta-de-usuario' }">
						<v-list-item-title>Cuenta de Usuario</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'mis-cupones' }">
						<v-list-item-title>Mis Cupones</v-list-item-title>
					</v-list-item>
					<v-list-item :to="{ name: 'historial' }">
						<v-list-item-title>Historial de compras</v-list-item-title>
					</v-list-item>
					<v-list-item @click.stop="logout()">
						<v-list-item-title>Cerrar Sesión</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<template v-slot:extension>
				<v-tabs
					align-with-title
					show-arrows
				>
					<v-tab :to="{ name: 'comprar' }" exact>
						Comprar Cupones
					</v-tab>

					<v-tab v-if="$store.state.loggedIn" :to="{ name: 'mis-cupones' }" exact>
						Mis Cupones
					</v-tab>

					<v-tab v-if="$store.state.loggedIn" :to="{ name: 'historial' }" exact>
						Historial de compras
					</v-tab>

					<v-tab v-if="$store.state.loggedIn" :to="{ name: 'cuenta-de-usuario' }" exact>
						Cuenta de Usuario
					</v-tab>

					<v-tab v-if="$store.state.loggedIn" @click.stop="logout()">
						Cerrar Sesión
					</v-tab>

					<v-tab v-else :to="{ name: 'login' }" exact>
						Iniciar Sesión
					</v-tab>

					<v-spacer></v-spacer>

					<v-tab>
						<v-btn icon href="https://www.facebook.com/complejo.americano.mh" target="_blank" title="Facebook">
							<v-icon>mdi-facebook</v-icon>
						</v-btn>

						<v-btn icon href="https://www.instagram.com/complejoamericano/" target="_blank" title="Instagram">
							<v-icon>mdi-instagram</v-icon>
						</v-btn>

						<v-btn icon href="https://wa.me/5492914253201" target="_blank" title="Whatsapp">
							<v-icon>mdi-whatsapp</v-icon>
						</v-btn>

						<v-btn icon href="mailto:reservas@complejoamericano.com" target="_blank" title="E-Mail">
							<v-icon>mdi-email</v-icon>
						</v-btn>
					</v-tab>
				</v-tabs>
			</template>
		</v-app-bar>

		<v-main>
			<v-container
				fluid
				fill-height
			>
				<v-layout
					align-top
					justify-center
				>
					<router-view></router-view>
				</v-layout>
			</v-container>
		</v-main>

		<v-footer>
			<span>&copy; {{ (new Date()).getFullYear() }} - Desarrollado por <a href="https://jaque.com.ar" target="_blank" rel="nofollow">JAQUE Software</a></span>
		</v-footer>
	</v-app>
</template>

<script>

export default {
	methods: {
		logout: function () {
      sessionStorage.removeItem('cupones-token')
      this.$store.state.loggedIn = false

      if (this.$route.name !== 'comprar') {
        this.$router.push({name: 'comprar'}, () => {})
      }
		},

		getUser: function () {
			this.$http.get('usuario')
			.then((response) => {
				if (response.data) {
					this.$store.state.loggedIn = true
				}
			})
			.catch(function (error) {
				//error.response.error.message
				console.log(error);
			})
			.then(() => {
				// TODO: finalizo llamada
			})
		},
	},

	mounted () {
		this.getUser()
	}
}
</script>
