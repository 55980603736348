import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: {
			es: {
				badge: 'Placa',
				close: 'Cerrar',
				dataIterator: {
					noResultsText: 'Ningún elemento coincide con la búsqueda',
					loadingText: 'Cargando...',
				},
				dataTable: {
					itemsPerPageText: 'Filas por página:',
					ariaLabel: {
						sortDescending: 'Orden descendente.',
						sortAscending: 'Orden ascendente.',
						sortNone: 'Sin ordenar.',
						activateNone: 'Pulse para quitar orden.',
						activateDescending: 'Pulse para ordenar descendente.',
						activateAscending: 'Pulse para ordenar ascendente.',
					},
					sortBy: 'Ordenado por',
				},
				dataFooter: {
					itemsPerPageText: 'Elementos por página:',
					itemsPerPageAll: 'Todos',
					nextPage: 'Página siguiente',
					prevPage: 'Página anterior',
					firstPage: 'Primer página',
					lastPage: 'Última página',
					pageText: '{0}-{1} de {2}',
				},
				datePicker: {
					itemsSelected: '{0} seleccionados',
					nextMonthAriaLabel: 'Próximo mes',
					nextYearAriaLabel: 'Próximo año',
					prevMonthAriaLabel: 'Mes anterior',
					prevYearAriaLabel: 'Año anterior',
				},
				noDataText: 'No hay datos disponibles',
				carousel: {
					prev: 'Visual anterior',
					next: 'Visual siguiente',
					ariaLabel: {
						delimiter: 'Carousel slide {0} of {1}',
					},
				},
				calendar: {
					moreEvents: '{0} más',
				},
				fileInput: {
					counter: '{0} archivos',
					counterSize: '{0} archivos ({1} en total)',
				},
				timePicker: {
					am: 'AM',
					pm: 'PM',
				},
				pagination: {
					ariaLabel: {
						wrapper: 'Navegación de paginación',
						next: 'Página siguiente',
						previous: 'Página anterior',
						page: 'Ir a la página {0}',
						currentPage: 'Página actual, página {0}',
					},
				},
				rating: {
					ariaLabel: {
						icon: 'Rating {0} of {1}',
					},
				}
			}
		},
		current: 'es',
	},
});
