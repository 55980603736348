import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import http from './plugins/http'
import eventHub from './plugins/eventHub'
import './plugins/vee-validate';

Vue.config.productionTip = false

Vue.prototype.$http = http
Vue.prototype.$eventHub = eventHub

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate: function () {
    this.$store.state.loggedIn = !!sessionStorage.getItem('cupones-token')
  },
}).$mount('#app')
