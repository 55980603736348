import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import es from 'vee-validate/dist/locale/es';

es.messages.doc_tipo = () => 'Tiene que especificar un CUIT para solicitar una Factura A.';

Validator.extend('doc_tipo', (value, [tipoCbte]) => {
  if ((tipoCbte == '001') && (value != 80)) {
    return false;
  }

  return true;
}, {
  hasTarget: true
});

Vue.use(VeeValidate);

Validator.localize('es', es);
