import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'comprar',
    meta: { layout: 'main', public: true },
    component: () => import('../views/Comprar.vue')
  },

  {
    path: '/mis-cupones',
    name: 'mis-cupones',
    meta: { layout: 'main', public: false },
    component: () => import('../views/MisCupones.vue')
  },

  {
    path: '/compra/:id/confirmada',
    name: 'compra-confirmada',
    meta: { layout: 'main', public: false },
    component: () => import('../views/CompraConfirmada.vue')
  },

  {
    path: '/mercado-pago',
    name: 'mercado-pago-back',
    meta: { layout: 'main', public: true },
    component: () => import('../views/MercadoPagoBack.vue')
  },

  {
    path: '/compras/historial',
    name: 'historial',
    meta: { layout: 'main', public: false },
    component: () => import('../views/HistorialCompra.vue')
  },

  {
    path: '/compras/historial/compra/:id',
    name: 'historial-detalle',
    meta: { layout: 'main', public: false },
    component: () => import('../views/HistorialDetalle.vue')
  },

  {
    path: '/login',
    name: 'login',
    meta: { layout: 'main', public: true },
    component: () => import('../views/Login.vue')
  },

  {
    path: '/cuenta-de-usuario',
    name: 'cuenta-de-usuario',
    meta: { layout: 'main', public: false },
    component: () => import('../views/CuentaUsuario.vue')
  },
  {
    path: '*',
    redirect: { name: 'comprar' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const authRequired = !to.meta.public
  const loggedIn = sessionStorage.getItem('cupones-token') // Es válido el token?

  if (authRequired && !loggedIn) {
    return next({ name: 'login' })
  }

  next()
})


export default router
